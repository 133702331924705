import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { meses } from 'src/app/common/constantes';
import { LocalStorageService } from 'src/app/common/local-storage';
import { TableColum } from 'src/app/model/table-column';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-tabla-generica',
  templateUrl: './tabla-generica.component.html',
  styleUrl: './tabla-generica.component.scss'
})
export class TablaGenericaComponent<T> {

  // variable con las columnas formateadas
  public _columns: TableColum[] = [];
  public _selectedColumns: TableColum[] = [];
  public _ocultablesColumns: TableColum[] = [];
  @Input("columasOcultable")
  public set columasOcultable(newData: any[]) {
    // se transforma el array de columnas al formato por defecto
    newData.forEach(a => this._ocultablesColumns?.push(new TableColum(a)));
  }

  @Input() data: Observable<T> | undefined;
  @Input() camposExpandidos: any; 
  expandedRows: { [key: string]: boolean } = {};
  @Input("columns")
  public set columns(newData: any[]) {
    if (newData) {
      newData.forEach(a => {
        // Verifica si la columna ya existe en _columns antes de agregarla
        const exists = this._columns.some(col => col.name === a.name);
        if (!exists) {
          // se transforma el array de columnas al formato por defecto
          this._columns.push(new TableColum(a));
        }
      });
    }
  }
  @Input() id: string = "";
  @Input() mensaje: string = "";
  @Input() numeroFilas: number = 10;
  @Input() tienePaginacion: boolean = true;
  @Input() sizeScroll: string = "2000px";
  @Input() nombreFichero;
  @Input() mostrarMensaje = true;
  @Input() mostrarBarraTareas = true;
  @Input() mostrarExcel = true;
  @Input() mostrarBuscador = true;
  @Input() tooltipDepartamento = false;

  @Input() valorOculto: any;
  @Output() checkboxChange = new EventEmitter<{valor: any, event: any}>();

  filters: string[] | undefined;
  results: any;

  @ViewChild('tabla') dt!: Table;

  constructor(private excelService: ExcelService, @Optional() private configDialog: DynamicDialogConfig, private cdref: ChangeDetectorRef, public router: Router, public localStorage: LocalStorageService) {

    // en el caso de fuera una carga en un diálogo
    if (this.configDialog) {
      this.data = this.configDialog.data.data;
      this.columns = this.configDialog.data.columns;
      this.id = this.configDialog.data.id;
      this.tienePaginacion = this.configDialog.data.tienePaginacion;
      this.nombreFichero = this.configDialog.data.nombreFichero;
    }
  }

    // marcar o desmarcar todos los checks de la lista pero no cambiar nunca los obligatorios
  // toggleSelectAll(columna: TableColum, event: any){
  //   event.originalEvent.stopPropagation();
  //   this.results?.forEach((d:any) => {
  //     if (!d[columna.campoObligatorio!]) {
  //       d[columna.name] = event.checked;
  //     }
  //   })
  // }

  getCol() {
    const colElim = this._ocultablesColumns.filter(col => !this._selectedColumns.find(col2 => col2.name == col.name))
    return this._columns.filter(col => !colElim.find(col2 => col2.name == col.name));
  }

  get selectedColumns(): TableColum[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: TableColum[]) {
    //restore original order
    this._selectedColumns = this._columns.filter((col) => val.includes(col));
  }

  soloOcultables() {
    return this._columns.filter(col => this._ocultablesColumns?.find(oc => oc.name == col.name));
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.nombreFichero = this.nombreFichero ? this.nombreFichero : this.mensaje;
    this.data!.subscribe((results: T) => {
      this.results = results;
      if (this.camposExpandidos) {
        this.results = Object.values(
          this.results.reduce((acc: Record<string, any>, item: any) => {
            if (item.idPadre == null) {
              // Si es un elemento padre (sin idPadre), inicializa con hijos como un array vacío
              acc[item.nombre] = { ...item, [this.camposExpandidos]: [] };
            } else {
              // Si es un hijo, búscalo por el idPadre y añádelo al array de hijos
              const parent = Object.values(acc).find((parent: any) => parent.id === item.idPadre);
              if (parent) {
                parent[this.camposExpandidos].push(item);
                parent[this.camposExpandidos].sort((a: any, b: any) => a.id - b.id);
              } else {
                // Si no tiene un padre asignado, se considera como un elemento individual sin hijos
                acc[item.nombre] = { ...item, [this.camposExpandidos]: [] };
              }
            }
            return acc;
          }, {} as Record<string, any>)
        );
      }
    });
    this.filters = this._columns.filter((e: any) => e.filter).map((f: any) => f.name);
  }

  applyFilterGlobal($event: any, stringVal: any) {
    const valor = ($event.target as HTMLInputElement).value;
    this.dt.filteredValue = this.results
      .map((nodo: any) => this.filtrarNodo(nodo, valor))
      .filter((nodo: any) => nodo !== null);
  }
  
  filtrarNodo(nodo: any, valor: string): any | null {
    // Verifica si el nodo coincide en alguna de sus propiedades, incluyendo propiedades anidadas
    const tieneCoincidenciaEnPropiedades = this.buscarEnPropiedades(nodo, valor);
  
    // Filtra los hijos recursivamente si existen
    let hijosFiltrados = [];
    if (Array.isArray(nodo.hijos)) {
      hijosFiltrados = nodo.hijos
        .map((hijo: any) => this.filtrarNodo(hijo, valor))
        .filter((hijo: any) => hijo !== null); // Elimina hijos que no coinciden
    }
  
    // Si el nodo actual coincide o alguno de sus hijos coincide, crea una copia del nodo con los hijos filtrados
    if (tieneCoincidenciaEnPropiedades || hijosFiltrados.length > 0) {
      return { ...nodo, hijos: hijosFiltrados };
    }
  
    // Si no hay coincidencia, retorna null para eliminar este nodo del filtrado
    return null;
  }
  
  // Método auxiliar para buscar coincidencias en propiedades, incluidas las que son objetos anidados
  buscarEnPropiedades(obj: any, valor: string): boolean {
    for (let key in obj) {
      if (key === 'hijos') continue; // Excluir hijos temporalmente
  
      const propiedad = obj[key];
  
      // Si la propiedad es un objeto, se busca recursivamente en sus propiedades
      if (typeof propiedad === 'object' && propiedad !== null) {
        if (this.buscarEnPropiedades(propiedad, valor)) {
          return true;
        }
      }
      // Si la propiedad es un string o número, realiza la comparación directa
      else if (propiedad?.toString().toLowerCase().includes(valor.toLowerCase())) {
        return true;
      }
    }
    return false;
  }
  


  limpiarFiltro(input: any) {
    this.dt.clear();
    input.value = "";
  }

  obtenerValor(item: T, column: TableColum) {
    //si la columna tiene formato propio, se invoca a su función
    if (column.format) {
      return column.format(item, column);
    } else if (column.name != 'acciones') {
      try {
        let valor = eval('item.' + column.name);
        if (typeof valor == 'number' && column.tipo != 'entero') {
          // para pintar con puntos de miles
          return valor.toLocaleString('de');
        }
        return valor;
      } catch (error) {

      }

    }
  }

  exportExcel(): void {
    //formateo de los datos para exportación
    let dataSelect = this.dt.filteredValue ? this.dt.filteredValue : this.dt.value;
    if(dataSelect![0].hasOwnProperty('esSeleccionado')){
      dataSelect = dataSelect!.filter((item:any) => item.esSeleccionado === true)
    }
    let data = dataSelect!.map((item: any) => {
      const newItem: any = {};
      // se fija la cabecera y el valor de los campos exportables
      this._columns.filter(col => col.exportable).forEach(col => {
          newItem[col.cabecera] = this.obtenerValor(item, col);
        }
      );
      //se añaden los registros hijos/expandibles
      if (this.camposExpandidos) {
        newItem[this.camposExpandidos] = item[this.camposExpandidos].map( (hijo: any) => {
          const newHijo: any = {};
          this._columns.filter(col => col.exportable).forEach(col => {
            newHijo[col.cabecera] = this.obtenerValor(hijo, col);
            }
          );
          newItem.esPadre = item.id;
          return newHijo;
        });
      }
      return newItem;
    });

    if (this.valorOculto){
      this.valorOculto.forEach((valor: any) => {
        data = data.map(d => ({ ...valor, ...d}));
      });
    }
    this.excelService.exportAsExcelFile(data, this.nombreFichero);
  }

  verificarMes(column: any) {
    if (meses.some(mes => mes.cabecera === column.cabecera)) {
      return true;
    } else {
      return false;
    }
  }

  moveCursorToStart(event: any) {
    const input = event.target; // Obtener el input del evento
    input.setSelectionRange(0, 0); // Mueve el cursor al inicio
  }

  obtenerEstilo(condicion: any, item: T) {
    if (condicion?.parametros) {
      return condicion!.funcion(item, condicion!.parametros) ? '' : 'display:none';
    } else {
      return condicion && (condicion!.funcion(item) ? '' : 'display:none');
    }
  }

  public borrarRegistro(registro: any) {
    this.results = this.results.filter((reg: any) => reg.id != registro.id);
  }
  mostrar() {
    return this.results && this.results.filter((res: any) => !res?.rowStyle?.includes("totales")).length > 0
  }

  onCheckboxChange(valor: any, event: any): void {
    this.checkboxChange.emit({ valor, event });
  }
}