<ng-container *ngIf="mostrar()">
    <p-table #tabla [value]="this.results" [dataKey]="this.id" [rows]="numeroFilas" [paginator]="tienePaginacion"
        [showCurrentPageReport]="true" [scrollHeight]="sizeScroll" [scrollable]="true"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        [globalFilterFields]="this.filters"
        [rowsPerPageOptions]="[5, 10, 20]" paginatorStyleClass="justify-content-end"
        [resizableColumns]="true" columnResizeMode="expand" styleClass="mt-3"
        [expandedRowKeys]="expandedRows">

        <!-- cabeceras -->
        <ng-template pTemplate="header">
            <tr>
                <ng-container *ngFor="let column of getCol()">
                    <!-- las innerHTML, no son ordenables-->
                    <th *ngIf="column.innerHTML" pFrozenColumn [frozen]="column?.frozen!" [class]="column.ancho" scope="col"  [innerHTML]="column.cabecera" >
                    </th>
                    <th *ngIf="!column.innerHTML" pFrozenColumn [frozen]="column?.frozen!" pResizableColumn [class]="column.ancho" scope="col" [pSortableColumn]="column.name" >
                        {{column.cabecera}}<p-sortIcon *ngIf="column.sortable" [field]="column.name"></p-sortIcon>
                        
                        <!-- <p-checkbox *ngIf="column.tipo == 'checkbox' && column.campoObligatorio != undefined"
                                [binary]="true" 
                                (onChange)="toggleSelectAll(column, $event)">
                        </p-checkbox> -->
                    </th>
                </ng-container>
            </tr>
        </ng-template>

        <!-- filtro general -->
        <ng-template pTemplate="caption" *ngIf="mostrarBarraTareas">
            <div class="flex align-items-center">
                <span class="m-2" *ngIf="mostrarMensaje">{{mensaje}}</span>
                <!-- <p-multiSelect *ngIf="_ocultablesColumns.length > 0"
                        display="chip" 
                        [options]="soloOcultables()" 
                        [(ngModel)]="selectedColumns" 
                        optionLabel="cabecera"
                        selectedItemsLabel="{0} columns selected" 
                        [style]="{'min-width': '200px'}" 
                        placeholder="Elegir columnas" />             -->
                <span class="flex-1 mr-4" style="text-align: end;" *ngIf="mostrarExcel">
                    <i class="pi pi-file-excel cursor-pointer excel" (click)="exportExcel()" pTooltip="Exportar a excel"
                        tooltipPosition="bottom"></i>
                </span>
                <span class="p-input-icon-left ml-auto m-2" *ngIf="mostrarBuscador">
                    <i class="pi pi-search"></i>
                    <input pInputText #search type="text" id="buscador" (input)="applyFilterGlobal($event, 'contains')"
                        placeholder="Buscar" />
                    <i class="pi pi-times clear" *ngIf="search.value" (click)="this.limpiarFiltro(search)"></i>
                </span>
            </div>
        </ng-template>

        <!-- celdas -->
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-expanded="expanded">
            <tr [class] = "item.rowStyle ? item.rowStyle : ''">
                <ng-container *ngFor="let column of getCol(); let i = index">
                
                    <!-- celdas no editables-->
                    <td [class]="i == 0 && item[camposExpandidos]?.length > 0 ? 'flex align-items-center' : ''" *ngIf="!column.editable &&
                                (column.class == undefined  || (column.class && column.class!(item, column) != 'totales'))" 
                                pFrozenColumn [frozen]="column?.frozen!">
                        
                        <p-button class="mr-2" *ngIf="i == 0 && item[camposExpandidos]?.length > 0" type="button" pRipple [pRowToggler]="item" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                        <ng-container *ngIf="column.tipo == 'checkbox'; else noEsChekbox" pFrozenColumn [frozen]="column?.frozen!">
                            <p-checkbox 
                                [binary]="true" 
                                [disabled]="column.campoObligatorio == undefined || (item[column.campoObligatorio!] && item[column.name])"
                                [(ngModel)]="item[column.name]"
                                (onChange)="onCheckboxChange(item, $event)">
                            </p-checkbox>
                        </ng-container>

                        <ng-template #noEsChekbox>
                            <span [class]="column.class && column.class(item, column)" (click)="column.click && column.click(item, column)" [pTooltip]="column.tooltip && column.tooltip(item)">{{ obtenerValor(item, column) }}</span>
                        </ng-template>
                        <!-- se pintan los iconos de acciones en caso de que los tenga-->
                        <ng-container *ngIf="!item[camposExpandidos] || item[camposExpandidos]?.length == 0">
                            <i *ngFor="let accion of column.acciones" [class]="accion.icon + ' text-center cursor-pointer'" [pTooltip]="accion.tooltip" tooltipPosition="bottom" (click)="accion.function(item)" [style]="obtenerEstilo(accion?.condicion, item)"></i>
                        </ng-container>
                    </td>                

                    <!-- celdas editables-->
                    <!-- el z-index, se añade porque las columnas bloqueadas con downdrop si no se ven cuando se despliegan -->
                    <td [style]="'z-index:' + (this.results.length - rowIndex)" *ngIf="column.editable && column.class!(item, column) != 'totales'" 
                            pFrozenColumn [frozen]="column?.frozen!" 
                            [pEditableColumn]="item[column.name]" 
                            [pEditableColumnField]="column.name">
                        <p-cellEditor class="p-fluid justify-content-center flex">
                            <ng-template pTemplate="input">
                                 <ng-container *ngIf="column.editable?.editableFun(item, column) != false">
                                    <p-inputNumber *ngIf="item.rowStyle != 'totales' && column.editable.typeData == 'number'" 
                                                    [min]="0" 
                                                    mode="decimal" 
                                                    [minFractionDigits]="1" 
                                                    [ngModel]="column.editable.obtenerObj!(item, column)" 
                                                    (onInput)="column.editable.obtenerObj!(item, column, $event)"
                                                    (onFocus)="moveCursorToStart($event)"></p-inputNumber>
                                    <div [style]="column?.style && column?.style(item)">
                                        <p-dropdown *ngIf="item.rowStyle != 'totales' && column.editable?.type == 'dropdown'" 
                                                [options]="column.editable.data" 
                                                [ngModel]="column.editable.obtenerObj!(item, column)" 
                                                (ngModelChange)="column.editable.obtenerObj!(item, column, $event)"
                                                [optionLabel]="column.editable.campoMostrado"></p-dropdown>
                                    </div>
                                </ng-container>
                                <span *ngIf="item.rowStyle == 'totales' || column.editable?.editableFun(item, column) == false" [class]="column.class && column.class(item, column)" [style]="column?.style && column?.style(item)">
                                    <div>{{  obtenerValor(item, column) }}</div>
                                    <ng-container *ngIf="column.editable?.type == 'dropdown'">
                                        <i class="pi pi-chevron-down ml-1"></i>
                                    </ng-container>
                                </span>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngIf="column.editable.typeData == 'number'" [class]="column.class && column.class(item, column)">{{  obtenerValor(item, column) }}</span>
                                <span *ngIf="column.editable?.type == 'dropdown'" [style]="column?.style && column?.style(item)" [class]="column.class && column.class(item, column)"><div class="elipsis">
                                    {{  obtenerValor(item, column) }}</div> 
                                    <div class="flex gap-2">
                                        <i class="pi pi-chevron-down ml-1"></i>
                                        <i *ngFor="let accion of column.acciones" [class]="accion.icon + ' text-center cursor-pointer'" [pTooltip]="accion.tooltip" tooltipPosition="bottom" (click)="accion.function(item)" [style]="obtenerEstilo(accion?.condicion, item)"></i>
                                    </div>
                                </span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <!-- para las filas de totales, no editables-->
                    <td pFrozenColumn [frozen]="column?.frozen!" *ngIf="column.class && column.class!(item) == 'totales'">
                       <span>{{  obtenerValor(item, column) }}</span>
                    </td>
                </ng-container>
            </tr>
        </ng-template>

        <!-- si tiene expansion -->
        <ng-template pTemplate="rowexpansion" let-item *ngIf="camposExpandidos">
            <tr>
                <td [attr.colspan]="getCol().length">
                    <div style="padding: 0.2rem 0rem 0.2rem 2.3rem">
                        <p-table [value]="item[camposExpandidos]" dataKey="id">
                            <ng-template pTemplate="header">
                                <tr>
                                    <ng-container *ngFor="let column of getCol()">
                                        <th pFrozenColumn  pResizableColumn [class]="column.ancho + ' headerSubtabla'" scope="col">
                                            {{column.cabeceraExp ? column.cabeceraExp : column.cabecera}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-template>

                            
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr [class] = "item.rowStyle ? item.rowStyle : ''">
                                    <ng-container *ngFor="let column of getCol()">
                                        <td [style]="i == 0 ? 'border: none' : ''">
                                            <span [class]="column.class && column.class(item, column)" (click)="column.click && column.click(item, column)" [pTooltip]="column.tooltip && column.tooltip(item)">
                                                {{ obtenerValor(item, column) }}
                                            </span>
                                            <!-- se pintan los iconos de acciones en caso de que los tenga-->
                                            <i *ngFor="let accion of column.acciones" [class]="accion.icon + ' text-center cursor-pointer'" [pTooltip]="accion.tooltip" tooltipPosition="bottom" (click)="accion.function(item)" [style]="obtenerEstilo(accion?.condicion, item)"></i>
                                        </td>                                        
                                    </ng-container>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-container>