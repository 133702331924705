<form [formGroup]="formulario" (keydown.enter)="retornar($event)">
    <ng-container *ngFor="let item of this.config.data.campos">
        <div *ngFor="let key of objectKeys(item)" class="flex align-items-center gap-3 mb-3">
            <ng-container *ngIf="item.tipo == 'label'">
                <b class="mt-3 mb-1">{{item.value}}</b>
            </ng-container>
            <ng-container *ngIf="item.tipo != 'label'">
                <label [for]="key" class="font-semibold w-4">{{ item[key] }}</label>
            
                <input *ngIf="item.tipo == 'text'" pInputText [formControlName]="key" class="flex-auto" autocomplete="off"
                    [class]="item.disabled ? 'deshabilitado' : ''" [readonly]="item.disabled"/>
                
                
                <p-checkbox *ngIf="item.tipo == 'check'" [binary]="true" [formControlName]="key"></p-checkbox>
                
                <p-calendar *ngIf="item.tipo == 'date-range'"
                        [formControlName]="key"
                        selectionMode="range"
                        [showIcon]="true"/>
            </ng-container>
        </div>
    </ng-container>
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancelar" severity="secondary" (onClick)="retornar($event, false)" />
        <p-button label="Aceptar" [disabled]="!formulario.valid" (onClick)="retornar($event, true)" />
    </div>
</form>


