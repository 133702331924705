import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { CRUDServiceService } from './crudservice.service';
import { Proyecto } from '../model/proyecto';
import { OpcionesExtension } from '../model/opcionesExtension';


const PATH = `${URL_HOME}/proyectos`;

@Injectable({
  providedIn: 'root'
})
export class ProyectosService<T> extends CRUDServiceService<Proyecto> {

  private _guardado = new BehaviorSubject<boolean>(false);

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  get getGuardado(): Observable<boolean> {
    return this._guardado.asObservable();
  }

  setGuardado(guardado: boolean) {
    this._guardado.next(guardado);
  }

  public getDepartamentos() : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/departamentos`).pipe(take(1));
  }
  public getTiposProyecto() : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/tiposProyecto`).pipe(take(1));
  }
  public getTiposImputacion() : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/tiposImputacion`).pipe(take(1));
  }
  public getIntegrantes() : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/integrantes`).pipe(take(1));
  }
  public getColoresActivos() : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/coloresActivos`).pipe(take(1));
  }

  public proyectosEstructura(fecha: Date, idUsuario: number): Observable<Proyecto[]> {
    //const params : HttpParams = new HttpParams({fromObject: filter || {}});
    return this.http.post<Proyecto[]>(`${PATH}/proyectosEstructura/${idUsuario}`, fecha).pipe(take(1));
  }

  public proyectosEstructuraMasiva(idUsuario: number): Observable<Proyecto[]> {
    //const params : HttpParams = new HttpParams({fromObject: filter || {}});
    return this.http.post<Proyecto[]>(`${PATH}/proyectosEstructuraMasiva/${idUsuario}`, null).pipe(take(1));
  }

  public updatePlanificacionEconomica(estructura: any) : Observable<any[]> {
    return this.http.post<T[]>(`${this.prefix}/updatePlanificacionEconomica`, estructura).pipe(take(1));
  }

  public duplicarProyecto(opciones: OpcionesExtension): Observable<Proyecto[]> {
    return this.http.post<Proyecto[]>(`${PATH}/duplicarProyecto`, opciones).pipe(take(1));
  }

  public extenderProyecto(opciones: OpcionesExtension): Observable<Proyecto[]> {
    //const params : HttpParams = new HttpParams({fromObject: filter || {}});
    return this.http.post<Proyecto[]>(`${PATH}/extenderProyecto`, opciones).pipe(take(1));
  }

  // public getAllProyectos(): Observable<T> {
  //   return this.http.get<T>(URL_HOME + "/proyecto/getAll").pipe(
  //     take(1)
  //   );
  // }
}
