import { EntityBase } from "./entityBase";
import { Tarea } from "./tarea";
import { Integrante } from "./integrante";
import { Usuario } from "./usuario";
import { Delegado } from "./delegado";
import { ConceptoSalarial } from "./conceptoSalarial";
import { FileWithType } from "./FileWithType";
import { TreeNode } from "primeng/api";

export class Proyecto extends EntityBase{

    public fechaIni?: Date;
	public fechaFin?: Date;
	public responsable?: Usuario;
	public importe?: number;
	public importeJustificar?: number;
	public tipo?: EntityBase;
	public departamento?: EntityBase;
	public estado?: EntityBase;
	public tipoImputacion?: EntityBase;
	public descripcion?: string;
	public integrantes?: Integrante[] = [];
	public delegados?: Delegado[] = [];
	
	public personalDirecto?: boolean;
	public personalIndirecto?: boolean;
	public gastosDirectos?: boolean;
	public gastosIndirectos?: boolean;
	public costeSimplificado?: boolean;
	
	public tareas?: Tarea[] = [];
	public conceptos?: ConceptoSalarial[] = [];
	public periodos?: EntityBase[] = [];
	public color?: string;
	public horasTotales?: number;
	public horasImputadas?: number;
	public horasRestantes?: number;
	public tieneImputaciones?: boolean = false;
	public fechaCierreParcial?: Date;
	public ficheros: FileWithType[] = [];
	public estructuraEconomica: TreeNode[] = [];
	public ejercicio?: string;
	public idPadre?: number;
	public nombrePadre?: string;
	public idUltimaExtension?: number;

    constructor(fields: Partial<Proyecto> = {}) {
        super();
        Object.assign(this, fields);
    }

	get nombreConEjercicio(): string {
		return this.nombre + (this.ejercicio ? ': ' + this.ejercicio : '');
	}
}